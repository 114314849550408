<template>
     <b-card>
        <app-view-table :title="trans('history',182)" :loading="loading_list" :isTitleTotal="true" :filters.sync="filters" :items="items" :fields="fields" :isDropdownMenu="false">
            
        </app-view-table>
    </b-card>  
</template>
<script>

import axios from "@axios";

export default {
    
    mounted() {
        this.onCreateThis();
    },

    data() {
        return {
            id_user: this.$route.params.id_user,
            loading_list: false,
            filters: {
                page: 1,
                per_page: 10,
                 sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0,

                },
                
                filtering: {},
                status: 1,
                changed: 0,
            },
            fields: [
                { key: "event_date", label: this.trans('date',182), thStyle: { "min-width": "90px", width: "100%" }, visible: true, filtering: false },
                { key: "ip_address_public", label: this.trans('settings-public-ip',207), thStyle: { "min-width": "200px", "max-width": "200px" }, visible: true, filtering: false  },
                { key: "route", label: this.trans('settings-route',207), thStyle: { "min-width": "200px", "max-width": "200px" }, visible: true, filtering: false  }, 
                
            ],
            items: [],
        };
    },

    methods: {
        getList: function() {
            this.loading_list = true;
            axios
                .post("settings/users/profile/logs/getList", {
                    user_id: this.id_user,
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {
                    this.items = res.data.items;
                    this.filters.page = res.data.page;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                });;
        },

        onCreateThis() { 

            this.getList();

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });
            this.$watch('filters.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });
            this.$watch('items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

        }
    },
 
};
</script>